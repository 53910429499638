<template>
	<header class="huangju-header">
		<!-- max -->
		<div class="max">
			<div class="content hj-layout">
				<div class="menu">
					<router-link to="/" class="logo">
						<img src="@/assets/images/common/logo.png" alt="logo" />
						河北凰聚科技有限公司
					</router-link>
					<div class="nav">
						<div class="item">
							<router-link class="title" to="/">首页</router-link>
						</div>
						<div class="item">
							<a class="title">产品</a>
							<ul class="sub-menu">
								<li>
									<router-link class="title"  to="/products/carbonNeutral">碳达峰、碳中和、产业链</router-link>
								</li>

								<li>
									<router-link class="title" to="/products/environmentaFriendly">环保</router-link>
									<div class="childer">
										<router-link to="/products/oilFumeSeparation" v-if="false">油烟分离</router-link>
										<router-link to="/products/sterilizer">等离子空气消毒器</router-link>
									</div>
								</li>
				
								<li>
									<router-link class="title" to="/products/culture">港深文化</router-link>
								</li>
								<li>
									<router-link class="title" to="/products/medical">医疗</router-link>
								</li>
								<li>
									<router-link class="title" to="/products/digitalVillage">数字乡村</router-link>
								</li>

								<li>
									<router-link class="title" to="/products/health">区域全民健康信息平台</router-link>
								</li>
							</ul>
						</div>
						
						<div class="item">
							<a class="title">关于我们</a>
							<ul class="sub-menu">
								<li>
									<router-link class="title" to="/about">企业概况</router-link>
								</li>
								<li>
									<router-link class="title" to="/about/culture">企业文化</router-link>
								</li>
								<li>
									<router-link class="title" to="/about/history">发展历程</router-link>
								</li>
								<li>
									<router-link class="title" to="/about/certificaties">资质荣誉</router-link>
								</li>
							</ul>
						</div>
						<div class="item">
							<router-link class="title" to="/concate">联系我们</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- min -->
		<div class="min">
			<div class="content hj-layout">
				<div class="menu">
					<router-link to="/" class="logo">
						<img src="@/assets/images/common/logo.png" alt="logo" />河北凰聚科技有限公司
					</router-link>
				</div>
				<div class="extend">
					<!-- <LayoutLocal /> -->
					<span class="iconfont icon-caidan" @click="showMenu = true"
						style="font-size: 24px; margin-left: 10px"></span>
					<!-- 层叠菜单 -->
					<div class="nav" :class="{ opened: showMenu }">
						<div class="item head">
							<router-link to="/" class="logo">
								<img src="@/assets/images/common/logo.png" alt="logo" />河北凰聚科技有限公司
							</router-link>

							<span class="iconfont icon-guanbi" style="font-size: 24px" @click="showMenu = false"></span>
						</div>

						<div class="item" @click.stop="showMenu = false">
							<router-link class="title" to="/">首页</router-link>
						</div>

						
						<div class="item">
							<a class="title" @click="isShowProducts = !isShowProducts">产品</a>
							<div class="child" v-show="isShowProducts">
								<router-link to="/products/carbonNeutral" @click.native="showMenu = false">碳达峰、碳中和、产业链</router-link>
								<router-link to="/products/oilFumeSeparation" @click.native="showMenu = false"  v-if="false">环保-油烟分离</router-link>
								<router-link to="/products/sterilizer" @click.native="showMenu = false">环保-等离子空气消毒器</router-link>
								<router-link to="/products/culture" @click.native="showMenu = false">港深文化</router-link>
								<router-link to="/products/medical" @click.native="showMenu = false">医疗</router-link>
								<router-link to="/products/digitalVillage" @click.native="showMenu = false">数字乡村</router-link>
								<router-link to="/products/health" @click.native="showMenu = false">区域全民健康信息平台</router-link>
							</div>
						</div>

						<div class="item">
							<router-link class="title" to="/about" @click.native="showMenu = false">关于我们</router-link>
						</div>
						<div class="item">
							<router-link class="title" to="/concate" @click.native="showMenu = false">联系我们</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template><script>
export default ({
	data() {
		return {
			showMenu: false,
			isShowProducts: false,
			isShowCase: false
		}
	},
	computed: {

	}
})
</script>
<style lang="less">
.huangju-header {
    background: #f1f1f1;
	color: #6a7593;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99;

	.content {
		display: flex;
		align-items: center;
		flex-direction: row;
		height: 80px;
		justify-content: space-between;

		.logo {
			height: 52%;
			display: block;
			font-size: 24px;
			font-weight: bold;
			color: #444;

			img {
				margin-right: 5px;
				max-height: 100%;
			}
		}

		.menu {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-grow: 1;
			justify-content: flex-start;
			height: 100%;

			.nav {
				padding-left: 20px;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				white-space: nowrap;

				.item {
					padding: 0 20px;
					position: relative;

					@media screen and (max-width: 1200px) {
						padding: 0 10px;
					}

					&:hover {
						.sub-menu {
							display: block;
						}
					}

					.title {
						font-size: 16px;
						cursor: pointer;
						padding: 16px 10px;
						display: block;
						overflow: hidden;
						white-space: nowrap;
						font-weight: 600;
					}

					.sub-menu {
						display: none;
						position: absolute;
						min-width: 160px;
						top: calc(100%);
						left: 10%;
						background-color: white;
						box-shadow: 0 5px 20px rgba(40, 95, 249, 0.2);
						border-radius: 10px;
						padding: 10px 0;

						li {
							padding: 0 10px;
							position: relative;
							.title {
								font-size: 16px;
								cursor: pointer;
								display: block;
								padding: 8px 16px;
								white-space: nowrap;
							}

							&:hover {
								.title {
									color: #285ff9;
								}
							}

							.childer{
								display: block;
								padding: 0 30px;
								background-color: #f0f0f0;
								border-radius: 4px;
								a{
									line-height: 36px;
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}

	.min {
		.content{

			.logo{
				font-size: 16px !important;
			}
		}
		.extend {
			align-items: center;

			.nav {
				position: fixed;
				top: 0;
				left: 100vw;
				bottom: 0;
				height: 100vh;
				z-index: 100;
				width: 100vw;
				background-color: white;
				transition: all 0.4s ease;

				&.opened {
					left: 0;
				}

				.item {
					position: relative;
					box-sizing: border-box;
					padding: 10px 20px ;
					justify-content: space-between;
					text-align: left;
					margin: 0 auto;
					border-bottom: 1px solid #f1f1f1;

					&:not(.head)::after {
						content: "";
						height: 10px;
						width: 10px;
						position: absolute;
						right: 15px;
						top: 23px;
						border-width: 1px 1px 0 0;
						border-color: #d4d4d4;
						border-style: solid;
						transform: translateY(-50%) matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
					}

					.title {
						width: 100%;
						display: block;
						font-size: 16px;
						text-align: left;
						font-weight: 400;
					}

					&.head {
						width: 100%;
						background-color: white;
						box-shadow: 0 0 30px rgba(40, 95, 249, 0.2);
						height: 74px;
						padding: 0 3vw;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
					}
				}
			}

			.child{
				width: 100%;

				a{
					padding: 5px 20px;
					display: block;
				}
			}
		}
	}

	.router-link-exact-active {
		color: #285ff9;
	}

	@media screen and (min-width: 1200px) {
		.max {
			display: block;
		}

		.min {
			display: none;
		}
	}

	@media screen and (max-width: 1200px) {
		.max {
			display: none;
		}

		.min {
			display: block;
		}
	}
}
</style>
