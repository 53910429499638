<template>
	<div class="iui-footer">
		<div class="layout">
			<div class="footl">
				<div class="menu">
					<router-link class="title" to="/products/carbonNeutral">碳达峰、碳中和、产业链</router-link> |
					<router-link class="title" to="/products/environmentaFriendly">环保</router-link>|
					<router-link class="title" to="/products/medical">医疗</router-link>|
					<router-link class="title" to="/products/digitalVillage">数字乡村</router-link>|
					<router-link class="title" to="/products/health">区域全民健康信息平台</router-link>
				</div>
				<p>
					运营中心地址：河北省石家庄市新华区中山西路77号恒大中心1303室&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
					联系电话：150-5719-5347
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系邮箱：huangju@163.com<br />
					Copyright &copy; {{ new Date().getFullYear() }} 河北凰聚科技有限公司 All Rights Reserved <a
						href="https://beian.miit.gov.cn/#/Integrated/index">冀ICP备2022019228号</a><br />
					技术支持：优时光年科技</p>
			</div>
			<!-- <div class="footr"><img src="@/assets/images/index/qrcode_for_gh_3e538f497955_430.jpg">服务微信号</div> -->
			<div class="footr"><img src="@/assets/images/index/qrcode_for_gh_3e538f497955_430.jpg">扫一扫关注我</div>
		</div>

		<a href="javascript:void(0);" @click="scrollTop" class="scrollTop" v-show="isShowScrolltop"><img
				src="@/assets/images/common/scrollTop.png"></a>
	</div>
</template>

<script>
export default ({
	data() {
		return {
			isShowScrolltop: false
		}
	},
	methods: {
		scrollTop() {
			$('body,html').stop().animate({ scrollTop: 0 }, 500)
		}
	},
	mounted() {
		let that = this
		window.onscroll = function () {
			let t = document.documentElement.scrollTop || document.body.scrollTop;
			if (t >= 100) {
				that.isShowScrolltop = true
			} else {
				that.isShowScrolltop = false
			}
		}
	},
	computed: {
		// 底部版权

	}
})
</script>
<style lang="less" scoped>
.scrollTop {
	position: fixed;
	bottom: 50px;
	right: 20px;
	background: #9b9ea5;
	display: block;

	img {
		max-width: 40px;
	}
}

.scrollTop:hover {
	background: #285ff9;
}

.iui-footer {
	background: #dfdfdf;

	.layout {
		display: flex;

		@media screen and(max-width:1200px) {
			flex-direction: column-reverse;
			padding: 0;
			margin: 0;
		}
	}
}

.iui-footer .footl {
	flex: 1;
	margin: 0 20px;
	padding-bottom: 10px;

	@media screen and (max-width:1200px) {
		margin: 0;
		padding: 0 20px 20px;
	}

	.menu {
		padding-top: 33px;
		line-height: 52px;
		border-bottom: solid 1px #b2b5bd;
		color: #333;
		font-size: 16px;

		@media screen and (max-width:1200px) {
			display: none;
		}

		a {
			padding: 0 10px;

			&:hover {
				color: #285ff9;
			}
		}

	}



	p {
		color: #333;
		padding-top: 20px;
		line-height: 27px;

		a {
			color: #333;
			text-decoration: none;
		}
	}
}


.iui-footer .footr {
	padding: 20px;
	line-height: 56px;
	text-align: center;
	color: #fff;
	background: #07c160;

	@media screen and(max-width:1200px) {
		line-height: 30px;
	}

	img {
		display: block;
		margin: 0 auto;
		text-align: center;
		color: #fff;
		width: 120px;
		height: 120px;
	}
}
</style>
