<template>
  <div id="app">
		<headerLayout></headerLayout>
    <router-view/>
		<footerLayout></footerLayout>
  </div>
</template>
<script>
import headerLayout from "@/components/header"
import footerLayout from "@/components/footer"

export default {
  name: 'Home',
  components: {
		headerLayout,
		footerLayout
  }
}
</script>
<style lang="less">
@import "./assets/css/common.less";
@import "./assets/iconfont/iconfont.css";
</style>
