import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: (resolve) => require(['@/views/Home'], resolve),
	},
	{
		path: '/about',
		name: 'About',
		component: (resolve) => require(['@/views/about/About'], resolve),
	},
	{
		path: '/about/history',
		name: 'history',
		component: (resolve) => require(['@/views/about/history'], resolve),
	},
	{
		path: '/about/certificaties',
		name: 'certificaties',
		component: (resolve) => require(['@/views/about/certificaties'], resolve),
	},
	{
		path: '/about/culture',
		name: 'culture',
		component: (resolve) => require(['@/views/about/culture'], resolve),
	},

	{
		path: '/products/digitalVillage', // 数字乡村
		name: 'digitalVillage',
		component: (resolve) => require(['@/views/products/digitalVillage'], resolve),
	},

	{
		name: 'environmentaFriendly',
		path: '/products/environmentaFriendly',
		component: resolve => require(['@/views/products/environmentaFriendly'], resolve),
		redirect: '/products/sterilizer',
		children: [
		  { path: '/products/oilFumeSeparation', component: resolve => require(['@/views/products/oilFumeSeparation'], resolve), name: 'oilFumeSeparation' },
		  { path: '/products/sterilizer', component: resolve => require(['@/views/products/sterilizer'], resolve), name: 'sterilizer' }
		]
	  },
	
	{
		path: '/products/medical', // 医疗
		name: 'medical',
		component: (resolve) => require(['@/views/products/medical'], resolve),
	},


	{
		path: '/products/carbonNeutral',
		name: 'carbonNeutral',
		component: (resolve) => require(['@/views/products/carbonNeutral'], resolve),
	},

	{
		path: '/products/health',
		name: 'health',
		component: (resolve) => require(['@/views/products/health'], resolve),
	},
	{
		path: '/products/culture',
		name: 'culture',
		component: (resolve) => require(['@/views/products/culture'], resolve),
	},

	
	{
		path: '/concate',
		name: 'concate',
		component: (resolve) => require(['@/views/concate'], resolve),
	}
]

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: "/",
	routes,
	scrollBehavior (to, from, savedPosition) {
	  return { x: 0, y: 0 }
	}
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};


export default router